import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Logo from '../../images/logo_hungarikum.svg';
import LogoBrown from '../../images/logo_hungarikum_brown.svg';

import './ModuleTitle.scss';

class ModuleTitle extends Component {
    render() {
        const myLogo = this.props.brown ? LogoBrown : Logo;
        return (
            <div className="module-title">
                <img src={myLogo} alt="logo"/>
                <label>{this.props.title}</label>
                <img src={myLogo} alt="logo" className="reversed"/>
            </div>
        )
    }
}

ModuleTitle.propTypes = {
    title: PropTypes.string,
    brown: PropTypes.bool
}

export default ModuleTitle;