import React, { Component } from 'react';
import ModuleTitle from "./ModuleTitle";
import AboutMe from "./AboutMe";

import _100Tagu from '../../images/100tagu.png';
import marci from '../../images/marci2.jpg';

import './HungarikumAboutUs.scss';

import {withTranslation} from "react-i18next";

class HungarikumAboutUs extends Component {
    render() {
        const {t} = this.props;
        return (
            <div id="hung-about-us">
                <ModuleTitle title={t('rolunk.rolunk')}/>
                <AboutMe picture={_100Tagu} name={t('rolunk.100Tagu')}>
                    {t('rolunk.100TaguLeiras')}
                </AboutMe>
                <AboutMe picture={marci} name={t('rolunk.marci')}>
                    {t('rolunk.marciLeiras')}
                </AboutMe>
            </div>
        )
    }
}

export default withTranslation()(HungarikumAboutUs);