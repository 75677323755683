import React, { Component } from 'react';
import PropTypes from 'prop-types';

import "./Button.scss";

class Button extends Component {

	render() {
		const btnColour = `button-${this.props.type.toLowerCase()}`;
		const btnClasses = ['button', btnColour];

		return (
			<a className={btnClasses.join(' ')} href={this.props.href} onClick={(event) => doScroll(event, this.props.href, this.props.onClick)}>
				{this.props.label}
			</a>
		)
	}
}

export function FancyButton({type, label, url, onClickHandler, target}) {

	const classes = ['button'];
	classes.push('button-' + type.toLowerCase());

	let btnTarget = "_self";
	if (target) {
		btnTarget = target;
	}

	let href = "javascript:void(null);";
	if (url) {
		href = url;
	}

	const handler = event => {
		if (onClickHandler) {
			onClickHandler(event);
		}
	}

	return (
		<a className={classes.join(' ')} target={btnTarget} href={href} onClick={event => handler(event)}>
			{label}
		</a>
	)
}

const doScroll = (event, id, onClick) => {
	if (onClick) {
		onClick();
	}
	// if (id.startsWith('#')) {
	// 	event.preventDefault();
	// 	document.getElementById(id.substr(1)).scrollIntoView({
	// 		behavior: "smooth",
	// 		block: "start",
	// 		inline: "nearest"
	// 	});
	// }
}

Button.propTypes = {
	type: PropTypes.oneOf(['WHITE', 'YELLOW', 'HUNG_ORANGE']).isRequired,
	label: PropTypes.string.isRequired,
	href: PropTypes.string.isRequired,
	onClick: PropTypes.func,
}

export default Button;