import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './AboutMe.scss';

class AboutMe extends Component {
    render() {
        const classNames = ['about-me'];
        if (!this.props.picture) {
            classNames.push('no-picture');
        }
        return (
            <div className={classNames.join(' ')}>
                <div className="picture">
                    <img src={this.props.picture} alt="me" className={this.props.imgClass}/>
                </div>
                <div className="description">
                    <div className="name amarillo-font">
                        {this.props.name}
                    </div>
                    <div className="text montserrat-font">
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}

AboutMe.propTypes = {
    picture: PropTypes.string,
    name: PropTypes.string,
    imgClass: PropTypes.string
}

export default AboutMe;