import React, {Component} from 'react';

import './HungarikumMain.scss';
import HK from '../../images/hungarikum_kavalkad_transp.png';
import Marci from '../../images/Marci_transparent_small.png';
import _100 from '../../images/100_zenekar.png';
import Trio from '../../images/trio.png';
import {withTranslation} from "react-i18next";

class HungarikumMain extends Component {
    render() {
        const {t} = this.props;
        return (
            <div>
                <div id="main">
                    <div id="hung-100">
                        <div id="hung-100-title" className="montserrat-font">
                            <div id="hung-100-big" className="hung-title">
                                <img src={HK} alt="Hungarikum Kavalkad"/>
                            </div>
                            <div id="hung-100-top" className="hung-title">
                                <span className="hung-title-white">100</span> {t('main.orszag')},&nbsp;
                                <span className="hung-title-white">100</span> {t('main.koncert')}
                            </div>
                            <div id="hung-100-bottom" className="hung-title">
                                <span className="hung-title-white">{t('main.100Tagu')}</span>,&nbsp;
                                <span className="hung-title-white">{t('main.klasszikusMagyarNota')}</span> {t('main.es')} <span
                                className="hung-title-white">{t('main.magyarOperrett')}</span>
                            </div>
                        </div>
                        <div id="desc-with-picture">
                            <div id="hung-desc" className="montserrat-font">
                                {t('main.A')} <span className="hung-desc-orange">{t('main.hungarikumKavalkad')}</span> {t('main.hungarikumMeghatarozas')}
                                <br/>
                                {t('main.a')} <span className="hung-desc-orange">{t('main.100Tagu')}</span>, {t('main.a')} <span
                                className="hung-desc-orange">{t('main.klasszikusMagyarNota')}</span> {t('main.es')} {t('main.a')} <span
                                className="hung-desc-orange">{t('main.magyarOperett')}</span>. {t('main.rendezvenyLeiras')}
                                <br/><br/>
                                {t('main.marcirol')}
                            </div>
                            <div id="marci-picture">
                                <img className="marci-img" src={Marci} alt="Maneszes Márton"/>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div id="trio" style={{backgroundImage: `url(${_100})`}}>*/}
                {/*    <div id="hung-trio">*/}
                {/*        <img src={Trio} alt="Trio"/>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        )
    }
}

export default withTranslation()(HungarikumMain);