import React from 'react';
import {createRoot} from "react-dom/client";
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import reportWebVitals from './reportWebVitals';

import Events from "./pages/Events.tsx";
import AppHungarikum from "./AppHungarikum";

import './index.css';

const router = createBrowserRouter([
    {
        path: '/',
        element: <AppHungarikum/>
    },
    {
        path: 'events/:id',
        element: <Events/>
    }
])

createRoot(document.getElementById('root')).render(
    <RouterProvider router={router}/>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
