import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Social.scss';

import EmailPng from '../images/email.png';
import FacebookPng from '../images/facebook3.png';
import InstagramPng from '../images/instagram4.png';

class Social extends Component {

	render() {
		let imgSrc;
		switch (this.props.type) {
			case "EMAIL": imgSrc = EmailPng; break;
			case "FB": imgSrc = FacebookPng; break;
			case 'INSTAGRAM': imgSrc = InstagramPng; break;
			default: imgSrc = null;
		}

		const classes = ['social', this.props.type.toLowerCase()];

		return (
			<a className={classes.join(' ')} href={this.props.href} target='_blank'>
				<img src={imgSrc} alt={this.props.type}/>
			</a>
		)
	}
}

Social.propTypes = {
	type: PropTypes.oneOf(['EMAIL']).isRequired,
	href: PropTypes.string.isRequired
}

export default Social;