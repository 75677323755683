import React, { Component } from 'react';
import ModuleTitle from "./ModuleTitle";
import AboutMe from "./AboutMe";


import './HungarikumMazi.scss';

import {withTranslation} from "react-i18next";

class HungarikumMazi extends Component {
    render() {
        const {t} = this.props;
        return (
            <div id="hung-about-us">
                <ModuleTitle title={t('mazi.mazi')}/>
                <AboutMe name={t('mazi.mazi')}>
                    {t('mazi.maziLeiras')}
                </AboutMe>
            </div>
        )
    }
}

export default withTranslation()(HungarikumMazi);