import React, { Component } from 'react';
import PropTypes from 'prop-types';

import date from '../../images/calendar.svg';
import video from '../../images/video.svg';
import place from '../../images/location.svg';

import './Koncert.scss';

class Koncert extends Component {
    render() {
        return (
            <div className="koncert montserrat-font">
                <div className="picture">
                    <img src={this.props.picture} alt="koncert"/>
                </div>
                <div className="details">
                    <div className="date">
                        <img src={date} alt="date"/>
                        <label>{this.props.date}</label>
                    </div>
                    <div className="place">
                        <img src={this.props.online ? video : place} alt="place"/>
                        <label>{this.props.place}</label>
                    </div>
                    <div className="content amarillo-font">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

Koncert.propTypes = {
    picture: PropTypes.string,
    date: PropTypes.string,
    place: PropTypes.string,
    online: PropTypes.bool,
    btnLabel: PropTypes.string,
    btnOnclickAction: PropTypes.func
}

export default Koncert;