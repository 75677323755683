import React, {Component} from 'react';

import './Partner100Tagu.scss';
import {withTranslation} from "react-i18next";
import LogoPartner from "../../images/logo_partner_red.jpg";

class Partner100Tagu extends Component {
    render() {
        const {t} = this.props;
        return (
            <div>
                <div id="partner100">
                    <div id="partner-100-big" className="hung-title">
                        <a href="https://www.100tagu.hu/" target="_blank">
                            <img id="logo-partner" src={LogoPartner} alt="Hungarikum Kavalkád & 100 Tagú Cigányzenekar"/>
                        </a>
                    </div>

                    <div id="partner-desc" className="montserrat-font">
                        <span className="hung-desc-orange-title">{t('partner100.kijelentes')}</span>
                        <br/><br/>
                        {t('main.A')} <a className="hung-desc-orange" href="https://www.100tagu.hu/" target="_blank"><span>{t('main.100Tagu')}</span></a>
                        <span>{t('partner100.leiras')}</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Partner100Tagu);