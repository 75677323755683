import React, { Component } from 'react';
import PropTypes from 'prop-types';

import "./Module.scss";

class Module extends Component {
	render() {
		const bgClass = `module-${this.props.background.toLowerCase()}`;
		const classes = ['module-wrapper', bgClass];
		if (this.props.style) {
			classes.push(`module-style-${this.props.style.toLowerCase()}`);
		}

		const widthWrapper = ['width-wrapper'];
		if (this.props.maxWidth) {
			const maxWidthClass = `module-width-${this.props.maxWidth.toLowerCase()}`;
			widthWrapper.push(maxWidthClass);
		}

		return (
			<div className={classes.join(' ')}>
				{this.props.offset && <div className="module-offset menu-bound" id={this.props.styleId}> </div>}
				<div className="module">
					<div className={widthWrapper.join(' ')}>
						{this.props.children}
					</div>
				</div>
			</div>
		)
	}
}

Module.propTypes = {
	background: PropTypes.oneOf(['WHITE', 'GRAY', 'YELLOW', 'DARK', 'BLACK', 'HUNG_BLACK', 'HUNG_GRAY', 'HUNG_BROWN', 'HUNG_ORANGE']).isRequired,
	maxWidth: PropTypes.oneOf(['FULL', 'THREEQ', 'TWOTHIRDS', 'SIXTY', 'HALF']),
	style: PropTypes.oneOf(['STICKY']),
	offset: PropTypes.bool,
	styleId: PropTypes.string,
}

export default Module;