import React, { Component } from 'react';
import AboutMe from "../../modules/hungarikum/AboutMe";

import ibi from '../../images/ibi2.jpg';
import arpi from '../../images/arpi2.jpg';

import './Kolozsvar2023Guests.scss';

import {withTranslation} from "react-i18next";
import ModuleTitle from "../../modules/hungarikum/ModuleTitle";

class Kolozsvar2023Guests extends Component {
    render() {
        const {t} = this.props;
        return (
            <div id="hung-guests">
                <ModuleTitle title={t('meghivottak.title')}/>
                <AboutMe picture={ibi} name={t('rolunk.ibi')} imgClass="small">
                    {t('rolunk.ibiLeiras')}
                </AboutMe>
                <AboutMe picture={arpi} name={t('rolunk.arpi')} imgClass="small">
                    {t('rolunk.arpiLeiras')}
                </AboutMe>
            </div>
        )
    }
}

export default withTranslation()(Kolozsvar2023Guests);