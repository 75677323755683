import React, {Component} from 'react';
import './LanguageSwitcher.scss'
import i18n from "../../i18n";

const languages = [
    {code: 'hu', name: 'Magyar'},
    {code: 'en', name: 'English'},
    // {code: 'de', name: 'Deutsch'},
    {code: 'ro', name: 'Romana'}
]


class LanguageSwitcher extends Component {
    state = {
        lang: 'hu'
    };


    changeLanguageHandler = (lang) => {
        this.setState({lang: lang});
    }

    render() {
        // console.log(this.state.lang)
        return (
            <span className="languageSwitcher">
                <LanguageSwitcherSelector
                    lang={this.state.lang}
                    handleChangeLanguage={this.changeLanguageHandler}
                />
            </span>
        )
    }
}

class LanguageSwitcherSelector extends Component {
    onChange = e => {
        this.props.handleChangeLanguage(e.target.className);
        i18n.changeLanguage(e.target.className);
    }

    render() {
        const options = languages.map(lng => {
            if (lng.code !== this.props.lang) {
                return <li key={lng.code} onClick={this.onChange}>
                    <div className={lng.code}/>
                </li>
            }
        });

        return (
            <div className="lang">
                <div className={this.props.lang}>
                </div>
                <ul className="dropdown">
                    {options}
                </ul>
            </div>
        )
    }
}

export default LanguageSwitcher;

