import React, { Component } from 'react';
import ModuleTitle from "./ModuleTitle";

import './HungarikumPartners.scss';

import mazi from '../../images/legy-1-10.png'
import nfinithree from '../../images/nfinithree_logo.png'
import kerekcsarda from '../../images/kerekcsarda.svg'
import levendulaViragbolt from '../../images/levendula.svg'
import deepcolors from '../../images/deep-colors.svg'
import kolozsvariRadio from '../../images/kolozsvari-radio.svg'
import bonanza from '../../images/bonanza.svg'


import {withTranslation} from "react-i18next";

class HungarikumPartners extends Component {
    render() {
        const {t} = this.props;
        return (
            <div id="hung-partners">
                <ModuleTitle title={t('partners.partners')}/>

                <div className="partners">
                    <div className="partnerTitle">
                        <span className="name">{t('partners.mazi')}</span>
                    </div>
                    <div className="grid">
                        <Partner name="MAZI" url="https://www.facebook.com/permalink.php?id=500696850417630&story_fbid=813574109129901" img={mazi}/>
                    </div>
                </div>
                <div  className="partners">
                    <div className="partnerTitle">
                        <span className="name">{t('partners.mainPartners')}</span>
                    </div>
                    <div className="grid">
                        <Partner name="nfinithree.com" url="https://www.nfinithree.com" img={nfinithree}/>
                    </div>
                </div>
                <div  className="partners">
                    <div className="partnerTitle">
                        <span className="name">{t('partners.inSupport')}</span>
                    </div>
                    <div className="grid">
                        <Partner name="Kerékcsárda" url="https://www.roatafaget.ro/" img={kerekcsarda}/>
                        <Partner name="Levendula virágbolt" url="https://www.facebook.com/FlorariaLavandaBaciu/" img={levendulaViragbolt}/>
                    </div>
                </div>
                {/*<div  className="partners">*/}
                {/*    <div className="partnerTitle">*/}
                {/*        <span className="name">{t('partners.media')}</span>*/}
                {/*    </div>*/}
                {/*    <div className="grid">*/}
                {/*        <Partner name="Kolozsvári Rádió" url="http://www.kolozsvariradio.ro/" img={kolozsvariRadio}/>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        )
    }
}

class Partner extends Component {
    render() {
        return (
            <div className="partner">
                <a href={this.props.url} target="_blank">
                    <img src={this.props.img} alt={this.props.name}/>
                </a>
            </div>
        );
    }
}

export default withTranslation()(HungarikumPartners);