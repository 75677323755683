import React, { Component } from 'react';
import ModuleTitle from "./ModuleTitle";
import AboutMe from "./AboutMe";

import bor1 from '../../images/bor1.png';
import polo1 from '../../images/polo1.png';
import polo2 from '../../images/polo2.png';
import polo3 from '../../images/polo3.png';
import polo4 from '../../images/polo4.png';

import './HungarikumProducts.scss';

import {withTranslation} from "react-i18next";

class HungarikumProducts extends Component {
    render() {
        const {t} = this.props;
        return (
            <div id="hung-products">
                <ModuleTitle title={t('termekek.termekek')}/>
                <AboutMe picture={bor1} name={t('termekek.bor')}>
                    {t('termekek.borLeiras')}
                </AboutMe>
                <AboutMe picture={polo1} name={t('termekek.polo')}>
                    {t('termekek.poloLeiras')}
                </AboutMe>
            </div>
        )
    }
}

export default withTranslation()(HungarikumProducts);