import React, { Component } from 'react';

import MenuItem from "../../common/MenuItem";
import LanguageSwitcher from "./LanguageSwitcher";

import Logo from "../../images/logo_hungarikum.svg";
import Partner100Logo from "../../images/logo_partner_black.jpg";
import MobileMenu from "../../images/mobile-menu.svg"
import './HungarikumHeaderModule.scss';

import {withTranslation} from "react-i18next";

class HungarikumHeaderModule extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mobileMenuVisible: false
        }

        this.lngs = {
            en: { nativeName: 'English' },
            hu: { nativeName: 'Magyar' }
        };
    }

    mobileMenuHandler = () => {
        this.setState({
            mobileMenuVisible: !this.state.mobileMenuVisible
        })
    }

    render() {
        const mobileMenuClasses = ['rollout-menu'];
        if (this.state.mobileMenuVisible) {
            mobileMenuClasses.push('visible');
        }

        const {t} = this.props;

        return (
            <div id="header">
                <div className="center">
                    <div id="logo">
                        <a href="#hungarikum" onClick={(event) => doScroll(event, 'hungarikum')}>
                            <img src={Logo} alt="logo"/>
                        </a>
                    </div>
                    <div className="menu">
                        <MenuItem label={t('menu.partner100Tagu')} href="#partner100Tagu" selected={this.props.selectedMenu === 'partner100Tagu'}/>
                        <MenuItem label={t('menu.koncertek')} href="#koncertek" selected={this.props.selectedMenu === 'koncertek'}/>
                        {/*<MenuItem label={t('menu.kozosseg')} href="#kozosseg" selected={this.props.selectedMenu === 'kozosseg'}/>*/}
                        {/*<MenuItem label={t('menu.rolunk')} href="#rolunk" selected={this.props.selectedMenu === 'rolunk'}/>*/}
                        <MenuItem label={t('menu.mazi')} href="#mazi" selected={this.props.selectedMenu === 'mazi'}/>
                        <MenuItem label={t('menu.mediatar')} href="#mediatar" selected={this.props.selectedMenu === 'mediatar'}/>
                        <MenuItem label={t('menu.partners')} href="#partners" selected={this.props.selectedMenu === 'partners'}/>
                        <MenuItem label={t('menu.elerhetoseg')} href="#elerhetoseg" selected={this.props.selectedMenu === 'elerhetoseg'}/>
                        {/*<div id="partner-logo">*/}
                        {/*    <a href="#hungarikum" onClick={(event) => doScroll(event, 'hungarikum')}>*/}
                        {/*        <img src={Partner100Logo} alt="partner-logo"/>*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                        <LanguageSwitcher/>
                    </div>
                    <div className="mobile-menu">
                        <LanguageSwitcher/>
                        <img src={MobileMenu} alt="mobile menu" className="mobile-menu-icon" onClick={() => this.mobileMenuHandler()}/>
                        <div className={mobileMenuClasses.join(' ')}>
                            <MenuItem label={t('menu.partner100Tagu')} href="#partner100Tagu" selected={this.props.selectedMenu === 'partner100Tagu'}
                                      callback={this.mobileMenuHandler} icon="logo"/>
                            <MenuItem label={t('menu.koncertek')} href="#koncertek" selected={this.props.selectedMenu === 'koncertek'}
                                      callback={this.mobileMenuHandler}/>
                            {/*<MenuItem label={t('menu.kozosseg')} href="#kozosseg" selected={this.props.selectedMenu === 'kozosseg'}*/}
                            {/*          callback={this.mobileMenuHandler}/>*/}
                            {/*<MenuItem label={t('menu.rolunk')} href="#rolunk" selected={this.props.selectedMenu === 'rolunk'} callback={this.mobileMenuHandler}/>*/}
                            <MenuItem label={t('menu.mazi')} href="#mazi" selected={this.props.selectedMenu === 'mazi'} callback={this.mobileMenuHandler}/>
                            <MenuItem label={t('menu.mediatar')} href="#mediatar" selected={this.props.selectedMenu === 'mediatar'}
                                      callback={this.mobileMenuHandler}/>
                            <MenuItem label={t('menu.partners')} href="#partners" selected={this.props.selectedMenu === 'partners'}
                                      callback={this.mobileMenuHandler}/>
                            <MenuItem label={t('menu.elerhetoseg')} href="#elerhetoseg" selected={this.props.selectedMenu === 'elerhetoseg'}
                                      callback={this.mobileMenuHandler}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const doScroll = (event, id) => {
    event.preventDefault();
    document.getElementById(id).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
    });
}

export default withTranslation() (HungarikumHeaderModule);


