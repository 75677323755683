import React, { Component } from 'react';
import ModuleTitle from "./ModuleTitle";
import MediaVideo from "./MediaVideo";

import './HungarikumMediatar.scss';

import {withTranslation} from "react-i18next";

class HungarikumMediatar extends Component {
    render() {
        const {t} = this.props;
        return (
            <div id="hung-media">
                <ModuleTitle title={t('mediatar.mediatar')}/>
                {/*<MediaVideo videoUrl="https://www.youtube.com/embed/GWtCFC72VPo" title="Lemezbemutató 2021"/>*/}
                <MediaVideo videoUrl="https://www.youtube.com/embed/joY-FxTE9zs" title="Hungarikum Kavalkád"/>
            </div>
        )
    }
}

export default withTranslation()(HungarikumMediatar);