import React, { Component } from 'react';

import './AppHungarikum.scss';
import './App.scss';

import Module from "./common/Module";
import HungarikumHeaderModule from "./modules/hungarikum/HungarikumHeaderModule";
import HungarikumMain from "./modules/hungarikum/HungarikumMain";
import HungarikumKoncertek from "./modules/hungarikum/HungarikumKoncertek";
import HungarikumAboutUs from "./modules/hungarikum/HungarikumAboutUs";
import HungarikumMazi from "./modules/hungarikum/HungarikumMazi";
import HungarikumMediatar from "./modules/hungarikum/HungarikumMediatar";
import HungarikumContact from "./modules/hungarikum/HungarikumContact";
import HungarikumKozosseg from "./modules/hungarikum/HungarikumKozosseg";
import HungarikumPartners from "./modules/hungarikum/HungarikumPartners";
import HungarikumProducts from "./modules/hungarikum/HungarikumProducts";
import Partner100Tagu from "./modules/hungarikum/Partner100Tagu";

class AppHungarikum extends Component {

    constructor(props) {
        super(props);

        this.state = {
            scrolledToTop: window.scrollY <= 0 ? undefined : 'STICKY',
            selectedMenu: null
        }

        setTimeout(() => {
            this.handleScroll(null);
        }, 100);
    }

    handleScroll = (event) => {
        const elements = document.getElementsByClassName('menu-bound'),
            y = window.scrollY,
            lastElement = elements[elements.length - 1],
            lastElementParent = lastElement.parentElement,
            atEnd = y + window.innerHeight >= lastElementParent.offsetTop + lastElementParent.offsetHeight;
        let i = 0,
            selectedMenu = this.state.selectedMenu;

        if (atEnd) {
            selectedMenu = elements[elements.length - 1].id;
        } else {
            while (i < elements.length) {
                const diff = y - elements[i].parentElement.offsetTop;
                if (diff >= -150 && diff <= 200) {
                    selectedMenu = elements[i].id;
                    break;
                }
                i++;
            }
        }

        this.setState({
            scrolledToTop: y <= 0 ? undefined : 'STICKY',
            selectedMenu
        });
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
        return (
            <div id="hungarikum" className="menu-bound">
                <Module background='HUNG_BLACK' style={this.state.scrolledToTop}>
                    <HungarikumHeaderModule selectedMenu={this.state.selectedMenu}/>
                </Module>
                <Module background='HUNG_BLACK' maxWidth="FULL" styleId="hung-main" offset={true}>
                    <HungarikumMain/>
                </Module>
                <Module background='HUNG_BLACK' styleId="partner100Tagu" offset={true}>
                    <Partner100Tagu/>
                </Module>
                <Module background='HUNG_BROWN' styleId="koncertek" offset={true}>
                    <HungarikumKoncertek/>
                </Module>
                {/*<Module background='HUNG_GRAY' styleId="termekek" offset={true}>*/}
                {/*    <HungarikumProducts/>*/}
                {/*</Module>*/}
                {/*<Module background='HUNG_BLACK' styleId="kozosseg" offset={true}>*/}
                {/*    <HungarikumKozosseg/>*/}
                {/*</Module>*/}
                {/*<Module background='HUNG_GRAY' styleId="rolunk" offset={true}>*/}
                {/*    <HungarikumAboutUs/>*/}
                {/*</Module>*/}
                <Module background='HUNG_GRAY' styleId="mazi" offset={true}>
                    <HungarikumMazi/>
                </Module>
                <Module background='HUNG_BLACK' styleId="mediatar" offset={true}>
                    <HungarikumMediatar/>
                </Module>
                <Module background='HUNG_GRAY' styleId="partners" offset={true}>
                    <HungarikumPartners/>
                </Module>
                <Module background="HUNG_ORANGE" styleId="elerhetoseg" offset={true}>
                    <HungarikumContact/>
                </Module>
            </div>
        );
    }
}

export default AppHungarikum;
