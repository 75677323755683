import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './MediaVideo.scss';

class MediaVideo extends Component {
    render() {
        return (
            <div className="media-video">
                <div className="title">{this.props.title}</div>
                <iframe width="700" height="394" src={this.props.videoUrl}
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                </iframe>
            </div>
        )
    }
}

MediaVideo.propTypes = {
    videoUrl: PropTypes.string,
    title: PropTypes.string
}

export default MediaVideo;