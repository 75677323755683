import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './MenuItem.scss';
import Logo from "../images/logo_hungarikum.svg";

class MenuItem extends Component {


	render() {
		const wrapperClasses = ['menu-item'];
		if (this.props.selected) {
			wrapperClasses.push('selected');
		}

		return (
			<div className={wrapperClasses.join(' ')}>
				<a href={this.props.href} onClick={(event) => {
					!this.props.noScroll && doScroll(event, this.props.href, this.props.callback)
				}}>
					{this.props.icon && <div id="logoMenu"><img src={Logo} alt="logo"/></div>}
					{this.props.label}
				</a>
			</div>
		)
	}
}

const normalizeId = (str) => {
	if (str.startsWith('#')) {
		return str.substr(1);
	}
	return str;
}

const doScroll = (event, id, callback) => {
	event.preventDefault();
	document.getElementById(normalizeId(id)).scrollIntoView({
		behavior: "smooth",
		block: "start",
		inline: "nearest"
	});
	if (callback) {
		callback();
	}
}

MenuItem.propTypes = {
	label: PropTypes.string.isRequired,
	href: PropTypes.string.isRequired,
	selected: PropTypes.bool,
	callback: PropTypes.func,
	noScroll: PropTypes.bool,
	icon: PropTypes.string
}

export default MenuItem;