import React, { Component } from 'react';
import ModuleTitle from "./ModuleTitle";
import Social from "../../common/Social";
import Button from "../../common/Button";

import './HungarikumContact.scss';

import {withTranslation} from "react-i18next";

class HungarikumContact extends Component {
    render() {
        const {t} = this.props;
        return (
            <div id="hung-contact">
                <ModuleTitle title={t('elerhetoseg.elerhetoseg')} brown={true}/>
                <div id="contact-form">
                    <div className="half-width">
                        <h2>
                            {t('main.hungarikumKavalkad')}
                        </h2>
                        <div className="socials">
                            <Social type='EMAIL' href='mailto:hello@hungarikumkavalkad.com'/>
                            <Social type='FB' href='https://www.facebook.com/hungarikumkavalkad/'/>
                            <Social type='INSTAGRAM' href='https://www.instagram.com/hungarikumkavalkad/'/>
                        </div>
                    </div>
                    <div className="half-width">
                        <input type="text" placeholder={t('elerhetoseg.nev')} name="name" id="contact-us-name"/>
                        <input type="email" placeholder={t('elerhetoseg.emailCim')} name="email" id="contact-us-email"/>
                        <textarea placeholder={t('elerhetoseg.uzenet')} id="contact-us-message"></textarea>
                        <Button href="javascript:void(null);" label={t('elerhetoseg.kuld')} type="WHITE" onClick={() => handleSubmitClick()}/>
                    </div>
                </div>

            </div>
        )
    }
}

const handleSubmitClick = () => {
    const nameEl = document.getElementById('contact-us-name'),
        name = nameEl.value,
        emailEl = document.getElementById('contact-us-email'),
        email = emailEl.value,
        messageEl = document.getElementById('contact-us-message'),
        message = messageEl.value;

    if (name && email && message) {
        fetch('https://hungarikum-kavalkad.ew.r.appspot.com/api/contact-us', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            referrer: "localhost:8080",
            referrerPolicy: "unsafe-url",
            body: JSON.stringify({
                name,
                email,
                message
            })
        }).then(data => console.log(data));
        nameEl.value = '';
        emailEl.value = '';
        messageEl.value = '';
    }
}

export default withTranslation()(HungarikumContact);