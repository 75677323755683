import React, { Component } from 'react';
import ModuleTitle from "./ModuleTitle";

import './HungarikumKozosseg.scss';

import {withTranslation} from "react-i18next";

class HungarikumKozosseg extends Component {
    render() {
        const {t} = this.props;
        return (
            <div id="hung-social">
                <ModuleTitle title={t('kozosseg.kozossegiMedia')}/>
                <div className="wrapper">
                    {/*<div className="column large">*/}
                    {/*    <iframe title="facebook1"*/}
                    {/*            src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fhungarikumkavalkad%2Fposts%2F171814457772307&show_text=true&width=500"*/}
                    {/*            height="700" scrolling="no" frameBorder="0"*/}
                    {/*            allowFullScreen="true"*/}
                    {/*            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>*/}
                    {/*    <iframe title="facebook4"*/}
                    {/*        src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fhungarikumkavalkad%2Fposts%2F122382686048818&show_text=true&width=500"*/}
                    {/*        height="560" scrolling="no" frameBorder="0"*/}
                    {/*        allowFullScreen="true"*/}
                    {/*        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>*/}
                    {/*</div>*/}
                    <div className="column">
                        {/*<iframe title="facebook2"*/}
                        {/*    src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fhungarikumkavalkad%2Fposts%2F145853897035030&show_text=true&width=500"*/}
                        {/*    height="480" scrolling="no" frameBorder="0"*/}
                        {/*    allowFullScreen="true"*/}
                        {/*    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>*/}
                        {/*<iframe title="facebook3"*/}
                        {/*    src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fhungarikumkavalkad%2Fposts%2F134734961480257&show_text=true&width=500"*/}
                        {/*    height="464" scrolling="no" frameBorder="0"*/}
                        {/*    allowFullScreen="true"*/}
                        {/*    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>*/}
                        {/*<iframe title="facebook5"*/}
                        {/*    src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fhungarikumkavalkad%2Fposts%2F133339224953164&show_text=true&width=500"*/}
                        {/*    height="464" scrolling="no" frameBorder="0"*/}
                        {/*    allowFullScreen="true"*/}
                        {/*    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>*/}
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(HungarikumKozosseg);