import React, {useEffect, useState} from 'react'

import Module from '../common/Module.jsx'
import Kolozsvar2023 from './events/Kolozsvar2023'
import HungarikumAboutUs from '../modules/hungarikum/HungarikumAboutUs.jsx'
import HungarikumMediatar from '../modules/hungarikum/HungarikumMediatar.jsx'
import Kolozsvar2023Guests from './events/Kolozsvar2023Guests.jsx'

import '../AppHungarikum.scss'
import '../App.scss'
import Kolozsvar2023Partners from "./events/Kolozsvar2023Partners";
import Kolozsvar2023Header from "./events/Kolozsvar2023Header.jsx";

export type EventsProps = {

}

function Events(props: EventsProps) {
    const [scrolledToTop, setScrolledToTop] = useState(window.scrollY <= 0 ? undefined : 'STICKY')
    const [selectedMenu, setSelectedMenu] = useState<string | null>(null)

    const handleScroll: EventListener = () => {
        const elements = document.getElementsByClassName('menu-bound'),
            y = window.scrollY,
            lastElement = elements[elements.length - 1],
            lastElementParent = lastElement.parentElement,
            lep = lastElementParent ? lastElementParent : {offsetTop: 0, offsetHeight: 0},
            atEnd = y + window.innerHeight >= lep.offsetTop + lep.offsetHeight;
        let i = 0,
            mySelectedMenu: string | null = selectedMenu;

        if (atEnd) {
            mySelectedMenu = elements[elements.length - 1].id;
        } else {
            while (i < elements.length) {
                const el = elements[i]
                const offsetTop = el && el.parentElement && el.parentElement.offsetTop ? el.parentElement.offsetTop : 0
                const diff = y - offsetTop
                if (diff >= -150 && diff <= 200) {
                    mySelectedMenu = elements[i].id;
                    break;
                }
                i++;
            }
        }

        setScrolledToTop(y <= 0 ? undefined : 'STICKY')
        setSelectedMenu(mySelectedMenu)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        setTimeout(() => {
            const event: MouseEvent = new (MouseEvent)('click',
                { 'view': window, 'bubbles': true, 'cancelable': true });
            handleScroll(event);
        }, 100);

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return <div id="hungarikum" className="menu-bound">
        <Module background='HUNG_BLACK' style={scrolledToTop}>
            <Kolozsvar2023Header selectedMenu={selectedMenu}/>
        </Module>
        <Module background='HUNG_BLACK' maxWidth="FULL" styleId="hung-main" offset={true}>
            <Kolozsvar2023/>
        </Module>
        <Module background='HUNG_BROWN' styleId="rolunk" offset={true}>
            <HungarikumAboutUs/>
        </Module>
        <Module background='HUNG_GRAY' styleId="meghivottak" offset={true}>
            <Kolozsvar2023Guests/>
        </Module>
        <Module background='HUNG_BLACK' styleId="mediatar" offset={true}>
            <HungarikumMediatar/>
        </Module>
        <Module background='HUNG_GRAY' styleId="partners" offset={true}>
            <Kolozsvar2023Partners/>
        </Module>
    </div>
}

export default Events