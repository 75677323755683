import {withTranslation} from "react-i18next"

const React = require("react")

const _100 = require("../../images/100_zenekar.png")
const Trio = require('../../images/trio.png')

// @ts-ignore
function Kolozsvar2023({ t }) {
    return <div>
        {/*<div id="main">*/}
        {/*    <div id="hung-desc" className="montserrat-font">*/}
        {/*        {t('main.A')} <span className="hung-desc-orange">{t('main.hungarikumKavalkad')}</span> {t('main.hungarikumMeghatarozas')}<br/>*/}
        {/*        {t('main.a')} <span className="hung-desc-orange">{t('main.100Tagu')}</span>, {t('main.a')} <span className="hung-desc-orange">{t('main.klasszikusMagyarNota')}</span> {t('main.es')} {t('main.a')} <span className="hung-desc-orange">{t('main.magyarOperett')}</span>. {t('main.rendezvenyLeiras')}*/}
        {/*    </div>*/}
        {/*</div>*/}
        <div id="trio" style={{backgroundImage: `url(${_100})`}}>
            <div id="hung-trio">
                <img src={Trio} alt="Trio"/>
            </div>
        </div>
    </div>
}

export default withTranslation()(Kolozsvar2023)